import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["authorizedOrganisations", "organisationLimited"]

  connect() {
    this.toggleOrganisationLimited()
  }

  toggleOrganisationLimited() {
    this.authorizedOrganisationsTarget.disabled = !this.organisationLimitedTarget.checked
  }
}
