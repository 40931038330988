import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"
import { REMEMBER_ME_LIFETIME, REMEMBER_ME_COOKIE } from "../constants"

export default class extends Controller {
  static targets = ["action", "email", "password", "passwordSection", "rememberMe"]

  connect() {
    this.passwordSectionShown = this.emailTarget.value.length > 0
    this.url = this.actionTarget.action

    this.remoteAuthDomains = JSON.parse(this.data.get("remote-auth-domains"))
    this.toggleRememberMe()
  }

  submit(event) {
    if (this.passwordSectionShown) return

    if (this.shouldPerformRemoteAuthentication) {
      this.performRemoteAuthentication()
    } else if (this.emailTarget.value.length > 0) {
      this.passwordSectionShown = true
      this.passwordTarget.focus()
    }

    event.preventDefault()
  }

  toggleRememberMe() {
    const inFifteenMinutes = new Date(new Date().getTime() + REMEMBER_ME_LIFETIME)
    Cookies.set(REMEMBER_ME_COOKIE, this.rememberMeTarget.checked.toString(), { expires: inFifteenMinutes })
  }

  // If the password section is hidden, but the value changes (because it's filled in by a password manager)
  // just show the password section to avoid having to press log in twice
  // But if both fields were filled, and the email would've fired remote authentication, do that instead
  passwordChanged() {
    if (this.passwordSectionShown) {
      return
    }

    if (this.shouldPerformRemoteAuthentication) {
      this.performRemoteAuthentication()
      return
    }

    this.passwordSectionShown = true
  }

  performRemoteAuthentication() {
    this.actionTarget.action = `${this.url}?login_hint=${encodeURIComponent(this.emailTarget.value)}`
    this.actionTarget.submit()
  }

  get passwordSectionShown() {
    return this.passwordSectionShownValue
  }

  set passwordSectionShown(value) {
    if (value) {
      this.passwordSectionTarget.classList.remove("d-none")
    } else {
      this.passwordSectionTarget.classList.add("d-none")
    }

    this.passwordSectionShownValue = value
  }

  get shouldPerformRemoteAuthentication() {
    const email = this.emailTarget.value.toLowerCase()
    const match = email.match(/@(.+)$/)
    const domain = match ? match[1] : null

    return this.remoteAuthDomains.includes(domain)
  }
}
