// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import $ from "jquery"
import "bootstrap"
import "@fortawesome/fontawesome-free/js/fontawesome"
import "@fortawesome/fontawesome-free/js/solid"

import { init as initApm } from "@elastic/apm-rum"

initApm({
  active: process.env.NODE_ENV === "production",
  serviceName: "atlas",
  serverUrl: "https://apm-intake.fluency.net.uk",
  distributedTracingOrigins: ["https://api.commsworld.com", "https://crm.commsworld.com"],
  environment: process.env.NODE_ENV,
})

document.addEventListener("submit", (event) => {
  if (event.defaultPrevented) return
  if (event.target.dataset.turboConfirm) return
  if (event.submitter.dataset.disableWith || event.target.classList.contains("button_to")) {
    setTimeout(() => { event.submitter.setAttribute("disabled", "") }, 0)
  }
})

document.documentElement.addEventListener("turbo:load", () => {
  $(".toast").toast("show")
  $("[data-toggle='tooltip']").tooltip()
})

$(document).on("shown.bs.modal", (event) => {
  event.target.querySelector("[autofocus=autofocus]").focus()
})
