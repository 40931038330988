import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  copy() {
    this.inputTarget.select()
    this.inputTarget.setSelectionRange(0, 99999)

    navigator.clipboard.writeText(this.inputTarget.value)
  }
}
